export const featureFlags = {
  INSTANT_REFUNDS: 'instant-refunds',
  MODIFY_IX_CHARGE: 'modify-ix-charge',
  OLIVE: 'olive',
  PRODUCT_RECOMMENDATIONS: 'product-recommendations',
  RECHARGE_ENABLED: 'recharge-refunds',
  GENERATE_QR_CODES_FOR_POS: 'generate-qr-codes-for-pos',
  SMART_RECOMMENDATIONS: 'smart-recommendations',
  EASYPOST_CARRIER_SUPPORT_TESTING: 'easypost-carrier-support-testing',
  STATIC_WORKFLOW_TEMPLATES: 'static-workflow-templates',
  STATIC_WORKFLOW_TEMPLATES_RETURNLY_TAGS: 'static-workflow-templates-returnly-tags',
  EXCHANGE_TRACKING: 'exchange-tracking',
  FORWARD_TRACKING_ENABLED: 'forward-tracking-enabled',
  DESTINATION_OVERRIDE: 'destination-override',
  TESTING_AND_VALIDATION_CREATE_ORDER_ENABLED: 'testing-and-validation-create-order-enabled',
  SHIP_BY_LOOP_CARRIERS_2: 'ship-by-loop-carriers-2',
  UPDATE_API_KEY_NAME: 'update-api-key-name',
  EXCLUDE_PRE_DISCOUNT_CREDIT: 'exclude-pre-discount-credit',
  WORKFLOWS_EXTERNAL_CALLS: 'workflow-external-calls',
  ASSIGN_EXCHANGE_TEST_TASK_ENABLED: 'assign-exchange-test-task-enabled',
  ASSIGN_REFUND_TEST_TASK_ENABLED: 'assign-refund-test-task-enabled',
  ASSIGN_STORE_CREDIT_TEST_TASK_ENABLED: 'assign-store-credit-test-task-enabled',
  PITNEY_BOWES_PRINT_BARCODE_ON_LABEL: 'pitney-bowes-print-barcode-on-label',
  PACKING_SLIP_TRANSLATION: 'packing-slip-translation',
  SHOP_SCOPED_ROLES_ENABLED: 'shop-scoped-roles-enabled',
  SHOP_CASH: 'shop-cash',
  FRAUD_DESIGN_ENHANCEMENTS: 'fraud-design-enhancements',
  REJECT_ITEM_EDIT_RETURN_ENABLED: 'reject-item-edit-return-enabled',
  WORKFLOWS_TESTBENCH: 'workflows-testbench',
  DELIVERY_RETURN_WINDOW: 'delivery-return-window',
  POINT_OF_SALE_WORKFLOW_CONDITION: 'point-of-sale-workflow-condition',
  NEW_ORDER_TRACKING_ADMIN_UI_ENABLED: 'new-order-tracking-admin-ui-enabled',
  ORDER_TRACKING_CUSTOMIZATIONS_ALLOWED: 'order-tracking-customizations-allowed',
  ORDER_TRACKING_CUSTOM_DOMAINS_ENABLED: 'order-tracking-custom-domains-enabled',
  TAX_INCLUSIVE_PACKING_SLIP_PRICES: 'tax-inclusive-packing-slip-prices',
  PAYMENTS_STRIPE_3DS2: 'payments-stripe-3ds2',
  AUTH0_SESSIONS_ENABLED: 'auth0-sessions-enabled',
  REJECT_RETURN_V2: 'reject-return-v2',
  MULTI_PRODUCT_NAV_ENABLED: 'multi-product-nav-enabled',
  ORDER_TRACKING_RECOMMENDATIONS: 'order-tracking-recommendations',
  RETURN_REFUND_SERVICE_ENABLED: 'return-refund-service-enabled',
  LIST_WITH_POSHMARK: 'list-with-poshmark',
  OCEANIA_ADDRESS_IMPROVEMENTS_ENABLED: 'oceania-address-improvements-enabled',
  MULTI_LABELS_ALLOWED: 'multi-labels-allowed',
  SHIPBOB_MULTI_WAREHOUSE: 'shipbob-multi-warehouse',
  PANDIUM_LEGACY_ONLY: 'pandium-legacy-only',
  PARTIAL_IX_REFUNDS: 'partial-ix-refunds',
  PER_PRODUCT_HANDLING_FEES: 'per-product-handling-fees',
  OFFSET_INTERNATIONAL_RETURN_POLICIES: 'offset-international-return-policies',
  CUSTOMIZED_FULFILLABLE_LOCATIONS: 'customized-fulfillable-locations',
};
